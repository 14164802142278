export type CreateContactInput = {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    message?: string;
};
export type RegisterNewsletterInput = {
};

export class GotbSdk {
    private endpoint: string;
    constructor(env: string) {
        this.endpoint = 'local' === env ? 'http://localhost:5310' : `https://site.api${env === 'prod' ? '' : `.${env}`}.gotombola.co`;
    }
    e2o(e: {  target: unknown }) {
        return Object.fromEntries(new FormData(e.target as any));
    }
    async request(uri: string, method: string, data?: unknown) {
        const res = await fetch(`${this.endpoint}${uri}`, {
            method,
            ...(data ? {body: JSON.stringify(data)} : {}),
            headers: {
                ...(data ? {
                    "Content-type": "application/json; charset=UTF-8",
                } : {}),
            },
        });
        let r: unknown = {};
        if (!res.ok) {
            try {
                r = await res.json();
            } catch (e) {
                // unable to retrieve report
                r = { status: 'error', message: 'Unexpected error occured' };
            }
            throw new Error((r as { status: string; message: string }).message);
        }
        try {
            r = await res.json();
        } catch (e) {
            // unable to retrieve result
            r = { status: 'error', message: 'Unable to retrieve result' };
            throw new Error((r as { status: string; message: string }).message);
        }
        return r;
    }
    async create(uri: string, data?: unknown) {
        return this.request(uri, 'POST', data);
    }
    async createContact(data: CreateContactInput) {
        return this.create('/contact', data);
    }
    async registerNewsletter(data: RegisterNewsletterInput) {
        return this.create('/newsletter', data);
    }
}

export function createEnvSdk(env: string) {
    return new GotbSdk(env);
}

export default createEnvSdk(import.meta.env.PUBLIC_ENV);